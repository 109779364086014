TODO: remove this file

<template>
  <div :class="{ 'icon-background': isSuccess }">
    <InformationAppModal
      :icon="isSuccess ? ICON_TYPE.CHECK_MARK : ICON_TYPE.CROSS_MARK"
      :shouldDisplayCloseButton="!isSuccess"
      @close="closeModal"
      :value="isOpen"
      :buttonTitle="isSuccess ? 'Continue' : 'Try again'"
      @confirm="buttonCallback"
    >
      <h1 class="modal-title">
        {{ isSuccess ? 'Documents submitted ' : 'Something went wrong!' }}
      </h1>
      <p class="status-body">
        {{
          isSuccess
            ? 'Your file(s) have been uploaded successfully'
            : "Sorry there's an unexpected error. Please try again later."
        }}
      </p>
    </InformationAppModal>
  </div>
</template>

<script>
import { useI18n } from 'vue-composable'
import InformationAppModal, {
  ICON_TYPE,
} from '@galileo/components/InformationAppModal/InformationAppModal'
import { AppButton, AppLink } from '@oen.web.vue2/ui'
import { useRouter } from '@galileo/composables/useRouter'

export default {
  name: 'DocumentsStatusModal',
  components: {
    InformationAppModal,
    AppButton,
    AppLink,
    ICON_TYPE,
  },
  props: {
    isOpen: { default: false },
    isSuccess: { required: true },
  },

  emits: ['closeModal'],
  setup(props, { emit }) {
    const router = useRouter()
    const buttonCallback = () => {
      if (props.isSuccess) router.replace('/activity')
      else router.replace('/document-upload')
      closeModal()
    }
    const { $t } = useI18n()

    const closeModal = () => {
      emit('closeModal')
    }
    return {
      $t,
      ICON_TYPE,
      closeModal,
      buttonCallback,
    }
  },
}
</script>

<style scoped>
::v-deep .overlay-content .card {
  max-width: 37rem;
}
::v-deep .card-header {
  @apply px-12;
}
::v-deep .card-header .card-header-right {
  @apply self-start;
}
::v-deep .card-footer {
  @apply items-center;
}
.modal-title {
  @apply text-3xl font-semibold;
}
.modal-text {
  @apply text-sm font-normal tracking-normal text-center;
}
::v-deep .text-underline {
  @apply underline;
}
::v-deep .link {
  @apply text-base font-bold;
  max-width: fit-content;
}
::v-deep .modal-body > p {
  @apply mt-3;
}
::v-deep .card-content .card-content-block {
  @apply mb-8;
}
::v-deep .card-header .card-header-title .icon-wrapper {
  @apply mt-10 mb-5 !important;
}
::v-deep .card-header .card-header-title {
  @apply mt-14;
  height: fit-content;
}
.icon-background {
  ::v-deep .card-header .card-header-title {
    @apply bg-gray-tertiary p-3 mt-10 rounded-full;
  }
}
</style>
