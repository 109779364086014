<template>
  <button class="balance-button" @click="goToIndividualCurrencyPage">
    <CircularFlag size="30" class="country-flag" :currency-code="currency" />
    <p class="total-balance">{{ $t('Balances.BalancesCompactCard.TotalBalance').value }}</p>
    <p class="balance-amount">{{ formatShortenCurrency(totalBalance) }} {{ currency }}</p>
    <p class="balance-available">
      {{ formatCurrency(currency, availableBalance) }}
      {{ $t('Balances.BalancesCompactCard.Available').value }}
    </p>
  </button>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import CircularFlag from '@galileo/components/CircularFlag/CircularFlag.vue'
import { useRouter } from '@galileo/composables/useRouter'
import { formatShortenCurrency, formatCurrency } from '@galileo/composables/useCurrency'

export default {
  name: 'BalanceButton',
  components: {
    CircularFlag,
  },
  props: {
    totalBalance: {
      type: Number,
      required: false,
      default: 0,
    },
    availableBalance: {
      type: Number,
      required: false,
      default: 0,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { $t } = useI18nStore()
    const router = useRouter()

    const goToIndividualCurrencyPage = () => {
      router.push(`/balances/${props.currency}`)
    }

    return {
      $t,
      goToIndividualCurrencyPage,
      formatShortenCurrency,
      formatCurrency,
    }
  },
}
</script>

<style scoped>
.balance-button {
  @apply bg-gray-200 rounded-xl flex flex-col items-start p-5 outline-none;
  min-width: 206px;
}
.country-flag {
  /* Add any styles needed for the country flag here */
  @apply mb-2;
}
.total-balance {
  @apply text-grayblue-300 text-xs leading-4;
}
.balance-amount {
  @apply font-semibold mb-1 text-lg;
}
.balance-available {
  @apply text-grayblue-300 text-xs font-semibold py-1 leading-3 px-2 rounded bg-gray-400 truncate;
}
</style>
