<template>
  <div class="action-block">
    <div class="actions-form-wrapper">
      <QTStylesWrapper class="flex flex-col gap-4 qt-app-input-ammount-currency">
        <AppInputAmountCurrency
          v-model="form.amountFrom"
          :data-value="form.amountFrom"
          should-reset-filter-on-focus
          :disabled="isProcessingTransaction || amountFromLoading"
          analytics-name="send-money-amount-to"
          :loading="amountFromLoading"
          :validation="validation.amountFrom"
          :currencies="convertFromBalanceList"
          :locale="deviceStore.getUserLocale"
          :currency.sync="form.currencyFrom"
          filter
          filter-check-additional-field
          :label="$t('PageConvertBalances.AmountFromInputLabel').value"
          additional-field-name="description"
          :filter-placeholder="$t('PageSendMoneyAmount.TypeToSearchPlaceholderText').value"
          :currency-label="$t('PageSendMoneyAmount.SelectCurrencyLabelText').value"
          expand-currency-input
          force-precision
          @focus="focusAmountFrom"
          @blur="blurAmountFrom"
        >
          <template #currency_selected="{ option }">
            <div class="flex flex-col justify-center h-full relative">
              <div class="flex flex-row">
                <CircularFlag class="mr-3" :currencyCode="option.value" />
                <p>
                  {{ option.value }}
                </p>
              </div>
              <p class="qt-currency-input-available">
                {{
                  $t('ComponentConvertingBalances.AvailableBalanceText', {
                    amount: formatCurrency(option.value, option.availableBalance),
                  }).value
                }}
              </p>
            </div>
          </template>
          <template #currency_option="{ option }">
            <div class="flex items-center gap-2">
              <CircularFlag class="mr-2" size="36" include-border :currencyCode="option.value" />
              <div class="currency-description">
                <span class="currency-text">{{ option.text }}</span>
                {{
                  $t('ComponentConvertingBalances.AvailableBalanceText', {
                    amount: formatCurrency(option.value, option.availableBalance),
                  }).value
                }}
              </div>
            </div>
          </template>
        </AppInputAmountCurrency>
        <SendMoneyQuoteErrorMessage
          v-if="errorTextFrom && form.amountFrom"
          :errorText="errorTextFrom"
        />
        <div class="currency-info-panel">
          <button
            class="swap-currencies-button"
            :class="{ loading: isSwapCurrenciesButtonLoading || isProcessingTransaction }"
            :disabled="isProcessingTransaction"
            @click="swapCurrencies"
          >
            <IconSwapCurrencies />
          </button>
          <QuickTransferSendRate :loading="isQuoteLoading" :disabled="isProcessingTransaction" />
        </div>
        <AppInputAmountCurrency
          v-model="form.amountTo"
          :data-value="form.amountTo"
          should-reset-filter-on-focus
          class="amount-to-input"
          :disabled="isProcessingTransaction || amountToLoading"
          analytics-name="send-money-amount-to"
          :loading="amountToLoading"
          :validation="validation.amountTo"
          :currencies="convertToBalanceList"
          :locale="deviceStore.getUserLocale"
          :currency.sync="form.currencyTo"
          filter
          filter-check-additional-field
          :label="$t('PageConvertBalances.AmounToInputLabel').value"
          additional-field-name="description"
          :filter-placeholder="$t('PageSendMoneyAmount.TypeToSearchPlaceholderText').value"
          :currency-label="$t('PageSendMoneyAmount.SelectCurrencyLabelText').value"
          expand-currency-input
          force-precision
          @focus="focusAmountTo"
          @blur="blurAmountTo"
        >
          <template #currency_selected="{ option }">
            <div class="flex flex-col justify-center h-full relative">
              <div class="flex flex-row">
                <CircularFlag class="mr-3" :currencyCode="option.value" />

                <p>
                  {{ option.value }}
                </p>
              </div>
              <p class="qt-currency-input-available">
                {{
                  $t('ComponentConvertingBalances.AvailableBalanceText', {
                    amount: formatCurrency(option.value, option.availableBalance),
                  }).value
                }}
              </p>
            </div>
          </template>
          <template #currency_option="{ option }">
            <div class="flex items-center gap-2">
              <CircularFlag class="mr-2" size="36" include-border :currencyCode="option.value" />
              <div class="currency-description">
                <span class="currency-text">{{ option.text }}</span>
                {{
                  $t('ComponentConvertingBalances.AvailableBalanceText', {
                    amount: formatCurrency(option.value, option.availableBalance),
                  }).value
                }}
              </div>
            </div>
          </template>
        </AppInputAmountCurrency>
        <SendMoneyQuoteErrorMessage
          v-if="(quoteErrorTo && form.amountTo) || !rate"
          :errorText="quoteErrorTo"
        />
      </QTStylesWrapper>
    </div>
    <ConvertBalancesSummary
      @confirm="submit"
      @on-accept-terms-click="onAcceptTermsClick"
      :is-quote-loading="isQuoteLoading"
      :isValid="!validation.$anyInvalid"
    />
    <PendingApprovalModal v-model="showPendingApprovalModal" />
    <BasicEddFieldsModal
      :open="showBasicEddModal"
      :disabled="!sendMoneyStore.isSubIndustrySelected"
      @on-subindustry-select="onSubIndustrySelect"
      @on-close="onBasicEddModalClose"
    />
    <ModalRouterView />
  </div>
</template>

<script>
import { computed, ref, reactive, toRef, onBeforeMount } from '@vue/composition-api'
import { required } from '@vuelidate/validators'
import { usePromiseLazy, useValidation } from 'vue-composable'
import { useRouter } from '@galileo/composables/useRouter'

import {
  QUOTE_SCREEN,
  PAYMENT_METHODS,
  DELIVERY_METHODS,
  TRANSFER_TYPE,
} from '@galileo/constants/sendMoneyFlow.const.js'

import { getFlag } from '@galileo/composables/useFlag'
import { ACCOUNT_KYC_STATUS } from '@galileo/constants/accountKycStatus.const.ts'

// Stores
import {
  useI18nStore,
  useSendMoneyStore,
  useAuthStore,
  useQuickTransferStore,
  useDeviceStore,
  useBalancesStore,
  useAppStore,
  useAnalyticsStore,
  useKYCRefreshCorporateStore
} from '@galileo/stores'

// components
import QTStylesWrapper from '@galileo/components/Views/QuickTransfer/StyleWrapperComponents/QTStylesWrapper'
import { AppInputAmountCurrency } from '@oen.web.vue2/ui'
import SendMoneyQuoteErrorMessage from '@galileo/components/Views/SendMoney/SendMoneyQuoteErrorMessage'
import QuickTransferSendRate from '@galileo/components/Views/QuickTransfer/QuickTransferSendRate/QuickTransferSendRate'
import ConvertBalancesSummary from '@galileo/components/Views/Balances/ConvertBalancesSummary.vue'
import { getAllInfoByCountry } from '@galileo/composables/useIsoCountryCurrencyLibrary'
import { formatCurrency } from '@galileo/composables/useCurrency'
import QuickTransferActionPageWrapper from '@galileo/components/Views/QuickTransfer/QuickTransferActionPageWrapper/QuickTransferActionsPageWrapper.vue'
import PendingApprovalModal from '@galileo/components/Views/SendMoney/Amount/PendingApprovalModal.vue'
import CircularFlag from '@galileo/components/CircularFlag/CircularFlag.vue'
import { useFetchUpdateQuotes } from '@galileo/composables/useFetchUpdateQuote'
import { IconSwapCurrencies } from '@galileo/assets/icons/quickTransferActions'
import {
  SEGMENT_EVENTS,
  SEGMENT_LOCATIONS,
  SEGMENT_LOCATIONS_ROUTES,
} from '@galileo/constants/segmentAnalytics'
import BasicEddFieldsModal from '@galileo/components/Views/QuickTransfer/Modals/BasicEddFields/ui/BasicEddFieldsModal.vue'
import { useBasicEddDialog } from '@galileo/components/Views/QuickTransfer/Modals/BasicEddFields/hooks/use-basic-edd-modal'
import ModalRouterView from '@galileo/views/ModalRouterView'
import { isEmpty } from 'lodash-es'

export default {
  name: 'ConvertBalance',
  components: {
    CircularFlag,
    QTStylesWrapper,
    AppInputAmountCurrency,
    SendMoneyQuoteErrorMessage,
    QuickTransferSendRate,
    ConvertBalancesSummary,
    QuickTransferActionPageWrapper,
    PendingApprovalModal,
    IconSwapCurrencies,
    BasicEddFieldsModal,
    ModalRouterView
  },
  setup() {
    const { $t } = useI18nStore()
    const sendMoneyStore = useSendMoneyStore()
    const authStore = useAuthStore()
    const quickTransferStore = useQuickTransferStore()
    const deviceStore = useDeviceStore()
    const balancesStore = useBalancesStore()
    const appStore = useAppStore()
    const analyticsStore = useAnalyticsStore()
    const kycRefreshCorporateStore = useKYCRefreshCorporateStore()

    const {
      fetchNewQuote: fetchNewQuoteBase,
      updatePreviousQuote,
      isQuoteLoading,
      swapCurrencies,
    } = useFetchUpdateQuotes()

    const router = useRouter()

    const isAccountNotApproved =
      authStore.userProfile.customer.accountKycStatus === ACCOUNT_KYC_STATUS.NOT_APPROVED

    const showPendingApprovalModal = ref(false)
    const focusedAmountFrom = ref(true)
    const focusedAmountTo = ref(false)

    
    const accountIsRestricted = sendMoneyStore.accountIsRestricted || authStore.userProfile.customer.accountRestricted

    const focusAmountFrom = () => {
      focusedAmountFrom.value = true
      blurAmountTo()
    }
    const blurAmountFrom = () => {
      focusedAmountFrom.value = false
    }
    const focusAmountTo = () => {
      focusedAmountTo.value = true
      blurAmountFrom()
    }
    const blurAmountTo = () => {
      focusedAmountTo.value = false
    }

    const routeFrom = appStore.getCurrentRoute.from

    onBeforeMount(async () => {
      quickTransferStore.isProcessingTransaction = true

      let shouldDefaultAmountFrom = false

      if (!appStore.isFromIncompleteTransaction) {
        sendMoneyStore.restoreDefaultForm()
        validation.$reset()
        shouldDefaultAmountFrom = true
      }

      sendMoneyStore.form.transferType = TRANSFER_TYPE.CONVERT_BALANCE

      await Promise.all([
        balancesStore.fetchTradableBalanceList(),
        balancesStore.fetchUserActiveBalanceList(),
      ])

      quickTransferStore.isProcessingTransaction = false

      const nativeCurrency = getAllInfoByCountry(authStore.userProfile.country).currency

      if (routeFrom.name === 'IndividualCurrency') {
        form.currencyFrom = routeFrom.params.currency
      } else {
        form.currencyFrom = nativeCurrency
      }

      const isEURAvailable = convertToBalanceList.value.find((currency) => currency.value === 'EUR')
      const isUSDAvailable = convertToBalanceList.value.find((currency) => currency.value === 'USD')

      if (authStore.userProfile.country === 'US' && isEURAvailable) {
        form.currencyTo = 'EUR'
      } else if (authStore.userProfile.country !== 'US' && isUSDAvailable) {
        form.currencyTo = 'USD'
      } else {
        // if EUR/USD is not available set currencyTo to the first available ccy in the array
        form.currencyTo = convertToBalanceList.value[0].value
      }

      sendMoneyStore.form.countryTo = authStore.userProfile.country

      sendMoneyStore.form.paymentMethod = PAYMENT_METHODS.FUNDS_ON_BALANCE
      sendMoneyStore.form.deliveryMethod = DELIVERY_METHODS.FUNDS_ON_BALANCE

      let locationForSegment = ref(SEGMENT_LOCATIONS.ACTIVITY)

      if (!appStore.currentRoute.params.locationFrom) {
        switch (routeFrom.name) {
          case SEGMENT_LOCATIONS.ACTIVITY:
            locationForSegment.value = SEGMENT_LOCATIONS_ROUTES.ACTIVITY
            break
          case SEGMENT_LOCATIONS_ROUTES.QUICK_TRANSFER:
            locationForSegment.value = SEGMENT_LOCATIONS.QUICK_TRANSFER
            break
          case SEGMENT_LOCATIONS_ROUTES.BALANCES_OVERVIEW:
            locationForSegment.value = SEGMENT_LOCATIONS.BALANCES_OVERVIEW
            break
          case SEGMENT_LOCATIONS_ROUTES.INDIVIDUAL_CURRENCY:
            locationForSegment.value = SEGMENT_LOCATIONS.SINGLE_CURRENCY_PAGE
            break
        }
      } else {
        locationForSegment.value = appStore.currentRoute.params.locationFrom
      }
      if (shouldDefaultAmountFrom) {
        form.amountFrom = sendMoneyStore.getDefaultAmountFrom()
      }
      analyticsStore.track({
        event: SEGMENT_EVENTS.CONVERT_BALANCE_ACCESSED,
        traits: {
          location: locationForSegment.value,
          tbuId: authStore.user.customer.businessUnitId,
          tbuRegion: authStore.user.customer.region,
        },
      })
    })

    const amountFromLoading = computed(() => {
      return form.amountTo > 0 && isQuoteLoading.value && sendMoneyStore.form.shouldCalcAmountFrom
    })
    const amountToLoading = computed(
      () => isQuoteLoading.value && form.amountFrom > 0 && !sendMoneyStore.form.shouldCalcAmountFrom
    )

    const fetchNewQuote = async ({ fixedInput = QUOTE_SCREEN.AMOUNT_FROM, debounceTime = 700 }) => {
      await fetchNewQuoteBase({ fixedInput, debounceTime })
      //check if converting is available
      if (canConvertBalances()) {
        if (validation.amountFrom.isInvalid.$invalid) {
          validation.amountFrom.isInvalid.$invalid = false
          validation.amountFrom.isInvalid.$error = null
        }
      } else {
        validation.amountFrom.isInvalid.$invalid = true
      }
    }

    const canConvertBalances = () => {
      const selectedQuote = sendMoneyStore.form.selectedQuote
      return (
        selectedQuote?.paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE &&
        selectedQuote?.deliveryMethod === DELIVERY_METHODS.FUNDS_ON_BALANCE
      )
    }

    const form = reactive({
      countryTo: computed({
        get: () => sendMoneyStore.form.countryTo,
      }),
      amountFrom: computed({
        get: () => sendMoneyStore.getAmountFrom(false),
        set: async (amount) => {
          sendMoneyStore.form.amountFrom = amount
          //reset quoteError
          sendMoneyStore.form.quoteErrorFrom = null
          await fetchNewQuote({ fixedInput: QUOTE_SCREEN.AMOUNT_FROM })
        },
      }),
      currencyFrom: computed({
        get: () => sendMoneyStore.getCurrencyFrom,
        set: async (currency) => {
          if (currency !== sendMoneyStore.form.currencyFrom) {
            sendMoneyStore.form.currencyFrom = currency
            if (sendMoneyStore.form.amountFrom) {
              await fetchNewQuote({ fixedInput: QUOTE_SCREEN.AMOUNT_FROM, debounceTime: 0 })
            }
          }
        },
      }),
      amountTo: computed({
        get: () => sendMoneyStore.getAmountTo(false),
        set: async (amount) => {
          sendMoneyStore.form.amountTo = amount
          await fetchNewQuote({ fixedInput: QUOTE_SCREEN.AMOUNT_TO })
        },
      }),
      currencyTo: computed({
        get: () => sendMoneyStore.form.currencyTo,
        set: async (currency) => {
          if (currency !== sendMoneyStore.form.currencyTo) {
            sendMoneyStore.form.currencyTo = currency

            if (currency === form.currencyFrom) {
              sendMoneyStore.form.currencyTo = sendMoneyStore.getFirstFavoriteCurrency(
                form.currencyFrom
              ).value
            }

            if (sendMoneyStore.form.amountTo) {
              await fetchNewQuote({ fixedInput: QUOTE_SCREEN.AMOUNT_TO, debounceTime: 0 })
            }
          }
        },
      }),
    })

    const validation = useValidation({
      quoteId: {
        $value: computed(() => sendMoneyStore.form.quoteId),
      },
      amountFrom: {
        $value: toRef(form, 'amountFrom'),
        isInvalid: {
          $validator() {
            return !sendMoneyStore.form.quoteErrorFrom
          },
          //use custom component in order to display the message / workaround to invalidate the field
          $message: ' ',
        },
        required: {
          $validator(v) {
            if (!sendMoneyStore.form.shouldCalcAmountFrom) {
              return v > 0
            }
            return true
          },
          $message: $t('PageSendMoneyAmount.AmountFromRequired').value,
        },
      },
      currencyFrom: {
        $value: toRef(form, 'currencyFrom'),
        required,
      },
      amountTo: {
        $value: toRef(form, 'amountTo'),
        isInvalid: {
          $validator() {
            return !sendMoneyStore.form.quoteErrorTo
          },
          $message: ' ',
        },
        required: {
          $validator(v) {
            if (sendMoneyStore.form.shouldCalcAmountFrom) {
              return v > 0
            }
            return true
          },
          $message: $t('PageSendMoneyAmount.AmountToRequired').value,
        },
      },
      currencyTo: {
        $value: toRef(form, 'currencyTo'),
        required,
      },
    })

    const processOrder = reactive(
      usePromiseLazy(() =>
        sendMoneyStore.processOrder({
          transferId: sendMoneyStore.form.transferId,
          replacePage: false,
        })
      )
    )

    const {
      basicEddErrors,
      showBasicEddModal,
      onBasicEddModalClose,
    } = useBasicEddDialog()

    const onAcceptTermsClick = async (isAccepted) => {
      if (!isAccepted && validation.$anyInvalid) {
        validation.$touch()
      } else if (isAccepted && validation.quoteId.$dirty) {
        sendMoneyStore.setConfirmedRate()

        // avoid opening basic EDD modal if form is already filled
        if (isEmpty(sendMoneyStore.form.filledAdditionalFields)) {
          basicEddErrors.value = sendMoneyStore.transferBasicResponse?.errors ?? []
        }

        await updatePreviousQuote()
        validation.$reset()
      }
    }

    const onSubIndustrySelect = (subIndustryId) => {
      sendMoneyStore.form.filledAdditionalFields = {
        ...sendMoneyStore.form.filledAdditionalFields,
        corpSubIndustry: subIndustryId,
      }
    }

    const submit = async () => {
      if (isAccountNotApproved) {
        showPendingApprovalModal.value = true
        return
      }

      if(accountIsRestricted) {
        router.push({ path: 'account-restricted', append: true })
        return
      }
      if (authStore.isCorporateAccount && kycRefreshCorporateStore.isOverdue) {
        kycRefreshCorporateStore.showModal({ isBlockingTransaction: true })
        return
      }

      quickTransferStore.isProcessingTransaction = true

      if (validation.quoteId.$dirty) {
        await updatePreviousQuote()
        validation.$reset()
      } else {
        sendMoneyStore.setConfirmedRate()
      }

      if (sendMoneyStore.didRateChange) {
        quickTransferStore.showRateChangedModal()
        sendMoneyStore.setConfirmedRate()
        return
      }

      //we stop refreshing the quote from now on.
      sendMoneyStore.clearRefreshQuote()
      sendMoneyStore.setConfirmedRate()

      await processOrder.exec()

      if (processOrder.error) {
        // TODO: Implement this logic for consumers
        const accountIsRestricted =
          sendMoneyStore.accountIsRestricted || authStore.userProfile.customer.accountRestricted
        if (!accountIsRestricted && !sendMoneyStore.isVerificationPending) {
          router.replace(processOrder.error)
        }
      } else {
        analyticsStore.track({
          event: SEGMENT_EVENTS.CONVERT_BALANCE_COMPLETED,
          traits: {
            convertCurrencies: { convertFrom: form.currencyFrom, convertTo: form.currencyTo },
            convertAmount: form.amountFrom,
            tbuId: authStore.user.customer.businessUnitId,
            tbuRegion: authStore.user.customer.region,
          },
        })
        router.replace(processOrder.result)
      }
    }

    const convertFromBalanceList = computed(() => {
      return balancesStore.tradableBalanceList.filter(
        (balance) => balance.accountCurrency !== form.currencyTo
      )
    })
    const convertToBalanceList = computed(() => {
      return balancesStore.tradableBalanceList.filter(
        (balance) => balance.accountCurrency !== form.currencyFrom
      )
    })

    const isSwapCurrenciesButtonLoading = computed(
      () => isQuoteLoading.value && form.amountFrom > 0
    )

    const errorTextFrom = computed(() => {
      const selectedQuote = sendMoneyStore.form.availableQuotes?.find(
        (quote) =>
          quote.paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE &&
          quote.deliveryMethod === DELIVERY_METHODS.FUNDS_ON_BALANCE
      )
      return selectedQuote?.errorFrom || sendMoneyStore.form.quoteErrorFrom
    })

    return {
      $t,
      getFlag,
      form,
      tradableBalanceList: computed(() => balancesStore.tradableBalanceList),
      isProcessingTransaction: computed(() => quickTransferStore.isProcessingTransaction),
      convertFromBalanceList,
      convertToBalanceList,
      deviceStore,
      amountFromLoading,
      amountToLoading,
      validation,
      submit,
      onAcceptTermsClick,
      sendMoneyStore,
      isQuoteLoading,
      showPendingApprovalModal,
      focusAmountFrom,
      blurAmountFrom,
      focusAmountTo,
      blurAmountTo,
      formatCurrency,
      swapCurrencies,
      isSwapCurrenciesButtonLoading,
      errorTextFrom,
      quoteErrorTo: computed(() => sendMoneyStore.form.quoteErrorTo),
      rate: computed(() => sendMoneyStore.form.rate),
      basicEddErrors,
      showBasicEddModal,
      onBasicEddModalClose,
      onSubIndustrySelect,
    }
  },
}
</script>

<style scoped>
::v-deep .currency-input .input-wrapper .input-input {
  @apply py-2;
  .selected-value {
    @apply h-full;
  }
}
::v-deep .qt-currency-input-available {
  @apply text-xs text-grayblue-300 -mb-2 leading-4 absolute bottom-1 right-0;
}
.swap-currencies-button {
  @apply border border-gray-500  rounded-lg;
  padding: 0px 1px;
  &:hover {
    @apply bg-gray-400;
  }
  &.loading {
    @apply opacity-50 pointer-events-none;
  }
}
.currency-info-panel {
  @apply flex flex-row items-center;
}
::v-deep .message-container {
  margin-top: -0.75rem;
}
</style>
