<template>
  <InformationAppModal
    icon="warningYellow"
    :value="model"
    :button-title="$t('PageActivity.ButtonGotIt').value"
    class="limit-exceeded-dialog-wrapper"
  >
    <template>
      <h1 class="title">
        {{ $t('Activity.LimitExceededTitle').value }}
      </h1>
      <p>
        {{ $t('Activity.LimitExceededDescription').value }}
      </p>
    </template>
    <template #footer>
      <AppButton analytics-name="activity-limit-exceeded-contact" @click="helpCentre">
        {{ $t('ContactUsModal.TitleText').value }}
      </AppButton>
      <AppButton analytics-name="activity-limit-exceeded-dismiss" theme="text" @click="closeModal">
        {{ $t('Activity.Dismiss').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { ref, defineComponent } from '@vue/composition-api'
import { useVModel, AppButton } from '@oen.web.vue2/ui'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal.vue'
import { useI18nStore, useAppStore } from '@galileo/stores'
import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'

export default defineComponent({
  components: {
    InformationAppModal,
    AppButton,
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const appStore = useAppStore()
    const { model } = useVModel(props, emit)
    const loadingHelpDesk = ref(false)

    const closeModal = () => {
      model.value = false
    }

    const helpCentre = () => {
      appStore.openHelpDesk({
        loadingRef: loadingHelpDesk,
        constName: HelpDeskProperties.SENDING_LARGE_AMOUNTS,
        article: HelpDesk[HelpDeskProperties.SENDING_LARGE_AMOUNTS],
        defaultHelpCentre: true,
      })
    }

    return {
      model,
      $t,
      closeModal,
      helpCentre,
    }
  },
})
</script>

<style scoped>
h1 {
  @apply text-2xl font-semibold flex justify-center;
}

p {
  @apply px-4 text-sm flex justify-center text-center;
}

.limit-exceeded-dialog-wrapper {
  ::v-deep section {
    @apply pt-6;
  }

  ::v-deep {
    .card-header {
      border-bottom: none;
      svg {
        width: 50px;
        height: 50px;
      }
    }
  }

  &.info-app-modal {
    scrollbar-gutter: auto;
  }
}

.button.button--text {
  height: 48px;
}
</style>
