<template>
  <div class="qt-actions-dropdown-wrapper">
    <p class="dropdown-prompt">{{ $t('QuickTransfer.IWantTo').value }}</p>
    <AppInputDropdown
      v-model="selectedAction"
      :options="actionList"
      class="qt-actions-dropdown flex-grow"
      analytics-name="select-recipient-dropdown "
      v-bind="$attrs"
      selected-display-field="fullName"
      selected-value-field="route"
      should-reset-filter-on-focus
      :placeholder="$t('ComponentRecipientSelectionDropdown.PlaceholderText').value"
      @input="handleAction"
    >
      <template #selected="{ option }">
        <div class="selected-option">
          <Component :is="option.icon" class="selected-icon" hide-background />
          <p class="option-label">
            {{ option.label }}
          </p>
        </div>
      </template>
      <template #option="{ option }">
        <div class="dropdown-option">
          <Component :is="option.icon" class="dropdown-option-icon" />
          <div class="option-label-wrapper">
            <p class="option-label">
              {{ option.label }}
            </p>
            <p class="option-subtitle">
              {{ option.subtitle }}
            </p>
          </div>
        </div>
      </template>
    </AppInputDropdown>
  </div>
</template>

<script>
import AppSkeleton from '@oen.web.vue2/ui/src/components/AppSkeleton/AppSkeleton.vue'
import { useI18nStore } from '@galileo/stores'
import { AppInputDropdown } from '@oen.web.vue2/ui'
import { ref, onMounted } from '@vue/composition-api'
import QTStylesWrapper from '../StyleWrapperComponents/QTStylesWrapper.vue'
import { useRouter } from '@galileo/composables/useRouter'
import {
  IconFundAction,
  IconSendAction,
  IconConvertAction,
} from '@galileo/assets/icons/quickTransferActions'
import { SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'
import { TRANSFER_TYPE } from '@galileo/constants/sendMoneyFlow.const'

export default {
  name: 'QuickTransferActionsDropdown',
  components: { AppSkeleton, AppInputDropdown, QTStylesWrapper },
  props: {
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()
    const selectedAction = ref('send-money')
    const actionList = ref([
      {
        route: 'one-step-transfer',
        label: $t('QuickTransfer.SendMoney').value,
        icon: IconSendAction,
        subtitle: $t('QuickTransfer.SendMoneySubtitle').value,
      },
      {
        route: 'fund-balance',
        label: $t('QuickTransfer.FundABalance').value,
        icon: IconFundAction,
        subtitle: $t('QuickTransfer.FundABalanceSubtitle').value,
      },
      {
        route: 'convert-balance',
        label: $t('QuickTransfer.ConvertABalance').value,
        icon: IconConvertAction,
        subtitle: $t('QuickTransfer.ConvertABalanceSubtitle').value,
      },
    ])

    onMounted(() => {
      selectedAction.value = router.history.current.fullPath.split('/').pop()
    })

    const handleAction = (route) => {
      if (route === 'fund-balance') {
        router.push({
          name: TRANSFER_TYPE.FUND_BALANCE,
          params: { locationFrom: SEGMENT_LOCATIONS.I_WANT_TO_MENU },
        })
      } else if (route === 'convert-balance') {
        router.push({
          name: TRANSFER_TYPE.CONVERT_BALANCE,
          params: { locationFrom: SEGMENT_LOCATIONS.I_WANT_TO_MENU },
        })
      } else {
        router.push(route)
      }
    }
    return {
      $t,
      handleAction,
      selectedAction,
      actionList,
    }
  },
}
</script>

<style scoped>
.qt-actions-dropdown-wrapper {
  @apply flex flex-row flex-wrap items-center gap-4;
}
.dropdown-prompt {
  @apply text-2xl font-semibold text-grayblue-400;
}

.option-label-wrapper {
  @apply flex flex-col justify-center;
}
.option-subtitle {
  @apply .text-xs leading-4;
}
.option-label {
  @apply font-semibold text-lg;
}
.dropdown-option {
  @apply h-full flex flex-row items-center gap-4 text-grayblue-400;
}
.selected-option {
  @apply h-full flex flex-row items-center;
}
.dropdown-option-icon {
  @apply h-10 w-10;
}
.qt-actions-dropdown {
  @apply m-0;
  min-width: 350px;
  ::v-deep {
    .input-wrapper {
      @apply mt-0;
    }
    .input-input {
      @apply h-14 border-2 border-transparent outline-none bg-gray-400 shadow-none pl-1;
      &.p-inputwrapper-focus {
        @apply border-grayblue-300;
      }
    }
    .input-label {
      @apply absolute top-1 left-3 font-normal text-grayblue-300 text-xs;
      z-index: 1;
    }

    .p-dropdown-label .selected-value {
      @apply text-grayblue-400;
    }

    .select-placeholder {
      @apply text-primary-text;
    }
    .p-dropdown-panel {
      top: 4rem !important;
      .p-dropdown-items-wrapper {
        @apply rounded-xl;
        .p-dropdown-items {
          @apply flex flex-col gap-2 p-2;
          .p-dropdown-item {
            @apply rounded-lg;
            &.p-highlight,
            &:hover {
              @apply bg-gray-200;
            }
            .dropdown-option {
              @apply p-2;
            }
          }
        }
      }
    }
  }
}
.qt-actions-dropdown.input--disabled {
  ::v-deep {
    .select-placeholder {
      @apply text-gray-disabled;
    }
    .p-dropdown-label .selected-value {
      @apply text-gray-disabled;
    }
    .selected-icon {
      @apply opacity-50;
    }
  }
}
</style>
