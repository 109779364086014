<template>
  <div class="balances-compact-card">
    <div class="balances-header-row">
      <h3 class="balances-title">{{ $t('Balances.BalancesCompactCard.Balances').value }}</h3>
      <p class="see-all-balances" @click.stop="goToBalancesPage">
        {{ $t('Balances.BalancesCompactCard.SeeAll').value }}
      </p>
    </div>
    <div class="balance-button-list-wrapper">
      <div v-if="isBalancesLoading" class="balances-loading">
        <AppSkeleton :loading="isBalancesLoading" height="142px" width="206px" />
        <AppSkeleton :loading="isBalancesLoading" height="142px" width="206px" />
        <AppSkeleton :loading="isBalancesLoading" height="142px" width="206px" />
      </div>

      <div v-else class="balance-button-list">
        <BalanceButton :currency="defaultCurrency" v-if="balanceList.length === 0" />

        <BalanceButton
          v-for="balance in balanceList"
          :key="balance.accountCurrency"
          :total-balance="balance.totalBalance"
          :currency="balance.accountCurrency"
          :available-balance="balance.availableBalance"
        />

        <!-- empty state button -->
        <button @click="goToFundABalancePage" class="balance-button-default">
          <div class="icon-padding">
            <AppIcon>
              <IconAdd />
            </AppIcon>
          </div>
          <p class="open-balance">{{ $t('Balances.BalancesCompactCard.OpenBalance').value }}</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutDashboardPage from '@galileo/components/LayoutDashboardPage/LayoutDashboardPage.vue'
import ProfileSelectionDropdown from '@galileo/components/Views/Account/Profile/ProfileSelectionDropdown.vue'
import XeSidebar from '@galileo/components/XeSidebar/XeSidebar.vue'
import { useRouter } from '@galileo/composables/useRouter'
import { AppCard, AppLink, AppIcon, AppFlag, AppSkeleton } from '@oen.web.vue2/ui'
import { IconAdd } from '@oen.web.vue2/icons'
import { getFlag } from '@galileo/composables/useFlag'
import { useBalancesStore, useCountriesStore, useI18nStore } from '@galileo/stores'
import { onMounted, ref, computed } from '@vue/composition-api'
import BalanceButton from './BalanceButton.vue'
import { SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

export default {
  name: 'BalancesCompactCard',
  components: {
    AppSkeleton,
    AppCard,
    LayoutDashboardPage,
    XeSidebar,
    ProfileSelectionDropdown,
    AppLink,
    IconAdd,
    AppIcon,
    AppFlag,
    BalanceButton,
  },
  setup() {
    const countriesStore = useCountriesStore()
    const balanceStore = useBalancesStore()
    const { $t } = useI18nStore()

    const router = useRouter()

    const isBalancesLoading = ref(true)

    const defaultCurrency = countriesStore.getCountryDefaultCurrency

    onMounted(async () => {
      isBalancesLoading.value = true
      await balanceStore.fetchUserActiveBalanceList()
      isBalancesLoading.value = false
    })

    const goToBalancesPage = () => {
      router.push({ name: 'BalancesOverview' })
    }

    const goToFundABalancePage = () => {
      router.push({ name: 'FundBalance', params: { locationFrom: SEGMENT_LOCATIONS.OPEN_BALANCE } })
    }

    return {
      $t,
      balanceList: computed(() => balanceStore.userActiveBalanceList),
      isBalancesLoading,
      goToBalancesPage,
      goToFundABalancePage,
      getFlag,
      defaultCurrency,
    }
  },
}
</script>

<style scoped>
::v-deep .skeleton {
  @apply my-0;
}
.balance-button-default {
  @apply bg-gray-200 rounded-xl p-5 flex flex-col;
  min-width: 206px;
}
.balance-button-default:focus,
.balance-button-default:active {
  @apply outline-none;
}

.balance-button-list {
  @apply flex flex-row gap-5 overflow-x-scroll pb-2;

  &::-webkit-scrollbar-thumb {
    @apply bg-gray-400 border-4;
  }
}

.balance-button-list-wrapper {
  @apply rounded-xl bg-white p-4 pb-1 mt-3;
}

.balances-title {
  @apply font-semibold text-lg text-grayblue-400 mb-3;
}

.balances-header-row {
  @apply flex flex-row justify-between;
}

.see-all-balances {
  @apply font-semibold text-grayblue-300 cursor-pointer pr-2;
}

.icon-padding {
  width: 30px;
  height: 30px;
  padding-top: 3px;
  @apply rounded-2xl bg-gray-400;
}

.open-balance {
  @apply mt-auto font-semibold mb-1 text-left;
}

.balances-loading {
  @apply flex flex-row gap-5 pb-2 overflow-hidden;

  ::v-deep .skeleton {
    @apply bg-gray-200 rounded-xl;
  }
}

@screen sm {
  .balance-button-list-wrapper {
    @apply p-8 pb-2;
  }

  .balances-title {
    @apply text-2xl;
  }

  .open-balance {
    @apply mb-2 text-lg;
  }

  .balances-loading {
    @apply pb-6;
  }
}
</style>
