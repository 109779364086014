<template>
  <div class="quick-transfer-summary-wrapper">
    <!-- waiting for design, this is just a dummy content -->
    <div v-if="!form.amountTo || !form.amountFrom" class="summary-no-data">
      <IconConvertAction />
      <h1 class="no-data-title">{{ $t('ComponentConvertBalanceSummary.NoDataTitle').value }}</h1>
      <p class="no-data-text">{{ $t('ComponentConvertBalanceSummary.NoDataText').value }}</p>
    </div>
    <div v-else class="relative">
      <h1 class="summary-title">
        {{ $t('ComponentConvertBalanceSummary.SummaryTitle').value }}
      </h1>
      <CardWarning v-if="shouldShowRateAlert && isAcceptedTermsAndConditions">
        <template #text>
          {{ $t('ComponentQuickTransferSummary.RateChangeWarningText').value }}
        </template>
      </CardWarning>
      <div v-if="form && form.selectedQuote" class="my-8">
        <QuickTransferSummaryItem
          icon="clock"
          :title="$t('Balances.ConvertBalance.ShouldArrive').value"
          :value="$t('Balances.ConvertBalance.WithinMinutes').value"
        />
        <QuickTransferSummaryItem
          icon="coins"
          :title="$t('ComponentConvertBalanceSummary.SendFees').value"
          :value="`${addPrecision(form.selectedQuote.fee)} ${form.currencyFrom}`"
        />

        <div class="total-to-pay">
          <p class="total-to-pay-text">
            {{ $t('ComponentConvertBalanceSummary.TotalToPayText').value }}
          </p>
          <p v-if="!isQuoteLoading" class="total-to-pay-amount">
            {{
              `${formatCurrency(form.currencyFrom, form.selectedQuote.totalAmount)} ${
                form.currencyFrom
              }`
            }}
          </p>
          <AppSkeleton
            v-if="isQuoteLoading"
            class="qt-skeleton-loader"
            loading
            height="14px"
            width="100px"
          />
        </div>
      </div>
    </div>
    <AppInputCheckbox
      v-if="form.amountTo && form.amountFrom"
      :value="isAcceptedTermsAndConditions"
      @input="onCheck"
      class="mb-3 mt-auto"
      :class="{
        loading: isCheckboxDisabled,
      }"
    >
      <span
        v-html="
          $t('ComponentTermsAndConditionsModal.TextIAgree', {
            termsAndConditionsURL: termsAndConditionsURL,
          }).value
        "
      ></span>
    </AppInputCheckbox>
    <AppButton
      v-if="form.amountTo && form.amountFrom"
      :disabled="disableConfirmButton"
      :loading="isProcessingTransaction"
      @click="confirm"
    >
      {{ $t('ComponentConvertBalanceSummary.ConfirmButton').value }}
    </AppButton>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import AppSkeleton from '@oen.web.vue2/ui/src/components/AppSkeleton/AppSkeleton.vue'

import {
  useI18nStore,
  useResourcesStore,
  useSendMoneyStore,
  useQuickTransferStore,
} from '@galileo/stores'

import { AppCard, AppButton, AppInputCheckbox, AppSpinnerBig } from '@oen.web.vue2/ui'

import { formatCurrency } from '@galileo/composables/useCurrency'
import CardWarning from '@galileo/components/CardWarning/CardWarning'
import QuickTransferSummaryItem from '@galileo/components/Views/QuickTransfer/QuickTransferSummary/QuickTransferSummaryItem.vue'
import { addPrecision } from '@galileo/utilities/filters'
import IconRecycle from '@galileo/assets/icons/vue/IconRecycle'
import { IconConvertAction } from '@galileo/assets/icons/quickTransferActions'

export default {
  name: 'ConvertBalanceSummary',
  emits: ['confirm', 'on-accept-terms-click'],
  components: {
    AppSkeleton,
    AppCard,
    AppButton,
    AppInputCheckbox,
    CardWarning,
    AppSpinnerBig,
    QuickTransferSummaryItem,
    IconRecycle,
    IconConvertAction,
  },
  props: {
    isQuoteLoading: {
      type: Boolean,
      required: true,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const resourcesStore = useResourcesStore()
    const sendMoneyStore = useSendMoneyStore()
    const quickTransferStore = useQuickTransferStore()

    const form = computed(() => sendMoneyStore.form)

    const isAcceptedTermsAndConditions = ref(false)

    const isQuoteError = computed(() => form.value.quoteErrorFrom !== null)

    const disableConfirmButton = computed(
      () =>
        form.value.quoteErrorFrom ||
        isQuoteError.value ||
        !isAcceptedTermsAndConditions.value ||
        props.isQuoteLoading ||
        isProcessingTransaction.value
    )
    const isQuoteDataInvalid = computed(
      () => !form.value.selectedQuote && !form.value.quoteErrorFrom && !form.value.quoteErrorTo
    )

    const isProcessingTransaction = computed(() => quickTransferStore.isProcessingTransaction)

    const isCheckboxDisabled = computed(
      () => isProcessingTransaction.value || isQuoteDataInvalid.value || props.isQuoteLoading
    )

    const onCheck = () => {
      if (isProcessingTransaction.value) {
        return
      }

      if (props.isValid) {
        isAcceptedTermsAndConditions.value = !isAcceptedTermsAndConditions.value
      }

      emit('on-accept-terms-click', isAcceptedTermsAndConditions.value)
    }

    watch(
      () => props.isValid,
      (isValid) => {
        if (!isValid) {
          isAcceptedTermsAndConditions.value = false
        }
      }
    )

    watch(
      () => sendMoneyStore.getConfirmedRate(),
      (confirmedRate) => {
        if (confirmedRate === null && isAcceptedTermsAndConditions) {
          sendMoneyStore.setConfirmedRate()
        }
      }
    )

    const confirm = () => {
      emit('confirm')
    }

    return {
      $t,
      isQuoteDataInvalid,
      isProcessingTransaction,
      quoteErrorFrom: computed(() => sendMoneyStore.form.quoteErrorFrom),
      shouldShowRateAlert: computed(() => sendMoneyStore.didRateChange),
      form,
      isAcceptedTermsAndConditions,
      termsAndConditionsURL: resourcesStore.getTermsAndConditionsUrl,
      disableConfirmButton,
      isCheckboxDisabled,
      onCheck,
      addPrecision,
      formatCurrency,
      confirm,
    }
  },
}
</script>

<style scoped>
.summary-title {
  @apply mb-4 text-xl text-grayblue-400 font-semibold;
}
.quick-transfer-summary-wrapper {
  @apply flex flex-col bg-white rounded-xl mx-auto p-8;
  flex: 1 1 0px;
  min-width: 300px;
}

.total-to-pay {
  @apply bg-gray-200 rounded-lg p-2 flex justify-between;
}
.total-to-pay-text {
  @apply text-grayblue-300 font-semibold;
}
.total-to-pay-amount {
  @apply text-grayblue-400 font-semibold;
}

::v-deep .input-checkbox.input-checkbox--with-label {
  @apply items-center;
}
.input-checkbox-label {
  @apply text-sm;
}
.input-checkbox--disabled {
  @apply opacity-50;
}

::v-deep .card-warning {
  @apply bg-yellow-lighter mb-0;
  font-size: 0.85rem;
}

::v-deep .input-checkbox .input-checkbox-label {
  @apply text-sm;
}

::v-deep .input-checkbox.loading {
  @apply text-gray-disabled pointer-events-none;
  .input-checkbox-virtual-checkbox-check {
    @apply border-gray-disabled;
  }
  .link {
    @apply text-gray-disabled;
  }
  &.input-checkbox--checked
    .input-checkbox-virtual-checkbox
    .input-checkbox-virtual-checkbox-check {
    @apply bg-gray-disabled;
  }
}

::v-deep .input-checkbox.input-checkbox--checked.input-checkbox--disabled .input-checkbox-label {
  @apply bg-transparent;
}

::v-deep .input-checkbox--checked .input-checkbox-virtual-checkbox:after {
  @apply hidden;
}

::v-deep
  .input-checkbox.input-checkbox--checked
  .input-checkbox-virtual-checkbox
  .input-checkbox-virtual-checkbox-check {
  @apply bg-dark-bg;
}
::v-deep .qt-skeleton-loader .skeleton {
  @apply bg-gray-500 rounded-full;
}
.summary-no-data {
  @apply flex py-16 items-center flex-col gap-3 flex-grow;
}
.no-data-title {
  @apply font-semibold;
}
.no-data-text {
  @apply text-sm text-gray-text text-center;
}
::v-deep .button.button--primary .button-inner {
  @apply px-4;
}
</style>
