<template>
  <LayoutDashboardPage title="">
    <template v-if="isBalancesEnabled">
      <TransactionShortcutButtons class="transaction-shortcut-buttons" />
      <BalancesCompactCard class="balance-card-compact" />
    </template>

    <AppTabs
      class="activity-tabs"
      :active-tab-index="activeTabIndex"
      @select="onTabSelect"
      :class="{ 'hide-tabs': !isQuickTransferSpecificAccount }"
    >
      <AppTabsTab :title="$t('PageActivity.ActivityTabTitle').value" analytics-name="activity-tab">
        <ActivityCard :is-tabs-showing="isQuickTransferSpecificAccount" class="mt-8" />
      </AppTabsTab>
      <AppTabsTab
        analytics-name="quick-transfer-tab"
        :title="$t('PageActivity.QuickTransferTabTitle').value"
        class="mb-20"
      >
        <QuickTransfer
          class="mx-3"
          v-if="isQuickTransferEnabled && activeTabName === 'QuickTransfer'"
        />
      </AppTabsTab>
    </AppTabs>
    <ModalRouterView />
    <LimitExceededDialog v-model="limitExceededDialogOpened" />
  </LayoutDashboardPage>
</template>

<script>
import ActivityCard from '@galileo/components/Views/Activity/ActivityCard.vue'
import { computed, onBeforeMount, ref } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'
import { storeToRefs } from 'pinia'
import { KYC_ATTEMPT_STATUS } from '@galileo/models/Cst/appVariables'

import LayoutDashboardPage from '@galileo/components/LayoutDashboardPage/LayoutDashboardPage'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

import TransactionShortcutButtons from '@galileo/components/Views/Balances/TransactionShortcutButtons/TransactionShortcutButtons'
import LimitExceededDialog from '@galileo/components/Views/Activity/Dialogs/LimitExceededDialog.vue'

import { ACCOUNT_TYPE } from '@galileo/constants/accountType.const'

import DocumentsStatusModal from '@galileo/components/DocumentsStatusModal/DocumentsStatusModal' // Example modal displayed int this file
import QuickTransfer from '@galileo/views/QuickTransfer.vue'
import CorporateEmptyTransactionList from '@galileo/components/Views/Activity/CorporateEmptyTransactionList.vue'

import ModalRouterView from '@galileo/views/ModalRouterView'
import { useMediaQuery, AppButton, AppSpinnerBig, AppTabs, AppTabsTab } from '@oen.web.vue2/ui'

import {
  useSendMoneyStore,
  useI18nStore,
  useAnalyticsStore,
  useActivityStore,
  useAuthStore,
  useTransactionStore,
  useAppStore,
} from '@galileo/stores'
import BalancesCompactCard from '@galileo/components/BalancesCompactCard/BalancesCompactCard.vue'

export default {
  name: 'Activity',
  components: {
    TransactionShortcutButtons,
    BalancesCompactCard,
    LayoutDashboardPage,
    AppButton,
    AppSpinnerBig,
    DocumentsStatusModal, // Example modal displayed int this file
    ModalRouterView,
    AppTabs,
    AppTabsTab,
    QuickTransfer,
    ActivityCard,
    CorporateEmptyTransactionList,
    LimitExceededDialog,
  },
  props: {
    activeTabName: {
      type: String,
      required: true,
      default: 'Activity',
    },
  },
  setup(props, { refs }) {
    const router = useRouter()
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const sendMoneyStore = useSendMoneyStore()
    const authStore = useAuthStore()
    const transactionStore = useTransactionStore()
    const activityStore = useActivityStore()
    const { user } = authStore
    const appStore = useAppStore()
    const title = computed(() => {
      return `${$t('PageActivity.WelcomeText').value}`
    })

    const isQuickTransferEnabled = authStore.getIsQuickTransferEnabled
    const isBalancesEnabled = authStore.getIsBalancesEnabled

    const isCorporateAccount = authStore.isCorporateAccount

    const documentStatusModalIsOpen = ref(false) // Example modal displayed int this file
    const documentsUploadedSuccessfully = ref(true) // Example modal displayed int this file

    const setDocumentsUploadedSuccessfully = (newValue) => {
      // Example modal displayed int this file
      documentsUploadedSuccessfully.value = newValue // Example modal displayed int this file
    }
    const setDocumentsStatusModal = (newValue) => {
      // Example modal displayed int this file
      documentStatusModalIsOpen.value = newValue // Example modal displayed int this file
    }

    const isQuickTransferSpecificAccount = ref(authStore.isQuickTransferSpecificAccount)

    const { limitExceededDialogOpened } = storeToRefs(sendMoneyStore)

    analyticsStore.track({
      event: SEGMENT_EVENTS.ACTIVITY_ACCESSED,
      traits: {
        location: SEGMENT_LOCATIONS.ACTIVITY,
      },
    })

    onBeforeMount(async () => {
      transactionStore.setActivityLoading()
      sendMoneyStore.shouldDisplayPaymentMethodUnavailableModal = false
      sendMoneyStore.shouldShowRecipientNotFoundModal = false

      if (isCorporateAccount) {
        await transactionStore.corporateFillGTMData()
      }

      if (
        isQuickTransferSpecificAccount.value &&
        !activityStore.hasTransactions &&
        appStore.currentRoute.name === 'Activity'
      ) {
        await router.replace('/quick-transfer')
      }

      transactionStore.setActivityReady()
    })

    const pendingOnfido = ref(false)
    const successfulOnfido = ref(false)
    const failedOnfido = ref(false)

    let checkOnfidoTimeout = null
    const checkOnfido = async (reload = false) => {
      if (checkOnfidoTimeout) {
        window.clearTimeout(checkOnfidoTimeout)
        checkOnfidoTimeout = null
      }
      if (reload) {
        await authStore.getUserAction()
      }

      const user = authStore.user

      pendingOnfido.value = false
      successfulOnfido.value = false
      failedOnfido.value = false
      const { isPendingVerifyUser } = authStore
      if (user?.kycStatus === KYC_ATTEMPT_STATUS.IN_PROGRESS) {
        activityStore.hasTransactions = true // since he already started a transaction show the transaction overview although he has no transactions yet
        pendingOnfido.value = true

        if (checkOnfidoTimeout) {
          window.clearTimeout(checkOnfidoTimeout)
          checkOnfidoTimeout = null
        }
        checkOnfidoTimeout = window.setTimeout(() => {
          checkOnfido(true)
        }, 5000)
      } else if (isPendingVerifyUser.value) {
        activityStore.hasTransactions = true // since he already started a transaction show the transaction overview although he has no transactions yet
        if (user?.kycStatus === KYC_ATTEMPT_STATUS.VERIFIED) {
          successfulOnfido.value = true
        } else {
          failedOnfido.value = true
        }
      }
    }
    checkOnfido()

    const showMissingDetails = () => {
      router.push('/all-transactions')
    }

    // ALL AVAILABLE TABS SHOULD BE ADDED INTO THIS ARRAY IN ORDER OF APPERANCE
    const orderedAvailableTabs = ['Activity', 'QuickTransfer']

    const activeTabIndex = computed(() => {
      return orderedAvailableTabs.indexOf(props.activeTabName)
    })

    const onTabSelect = (e) => {
      if (
        e.analyticsName === 'quick-transfer-tab' &&
        router.history.current.name !== 'QuickTransfer'
      ) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.QUICK_TRANSFER_ACCESSED,
          traits: {
            location: SEGMENT_LOCATIONS.QUICK_TRANSFER,
            accountType: isCorporateAccount ? ACCOUNT_TYPE.CORPORATE : ACCOUNT_TYPE.CONSUMER,
            tbuId: authStore.user.customer.businessUnitId,
            tbuRegion: authStore.user.customer.region,
          },
        })
        router.replace('/quick-transfer')
      } else if (e.analyticsName === 'activity-tab' && router.history.current.name !== 'Activity') {
        router.replace('/activity')
      }
    }

    const isCorpTransactionListEmpty = computed(
      () => !transactionStore.loadingActivityList && !activityStore.hasTransactions
    )

    return {
      activityStore,
      onTabSelect,
      isCorporateAccount,
      isBalancesEnabled,
      isQuickTransferEnabled,
      documentStatusModalIsOpen, // Example modal displayed int this file
      setDocumentsStatusModal, // Example modal displayed int this file
      setDocumentsUploadedSuccessfully, // Example modal displayed int this file
      documentsUploadedSuccessfully, // Example modal displayed int this file
      mq: useMediaQuery(),
      title,
      user,
      loadingDetails: computed(() => transactionStore.loadingDetails),
      loadingShare: computed(() => transactionStore.loadingShare),
      selectedTransaction: computed(() => transactionStore.selectedTransaction),
      $t,
      showMissingDetails,
      isQuickTransferSpecificAccount,
      activeTabIndex,
      isCorpTransactionListEmpty,
      limitExceededDialogOpened,
    }
  },
}
</script>

<style scoped>
.balance-card-compact {
  @apply mx-3;
  @screen sm {
    @apply mx-0;
  }
}

.transaction-shortcut-buttons {
  @apply my-6 mx-3;
  @screen sm {
    @apply mx-0 mt-0;
  }
}
.pay-modal {
  ::v-deep .card {
    width: 70vw;
    max-width: 70vw;
  }
}

.send-money-button {
  @apply w-full !important;
  @apply mx-auto;

  ::v-deep .button-inner {
    @apply py-2;
  }

  background: rgb(242, 245, 254);
  border-radius: 8px;
}

.noPendingTransactions {
  @apply border-t;
  @apply border-b;
  border-color: #f3f4f6;
}

.missing-details {
  @apply mt-4;
  @apply flex flex-col items-center;
  display: none;
  text-align: center;
}
.missing-details .button {
  @apply w-auto;
}
.missing-details-small {
  display: flex;
}
::v-deep .md-ripple {
  @apply z-0;
}
@screen sm {
  .missing-details {
    display: flex;
  }
  .missing-details-small {
    display: none;
  }
}
@screen md {
  ::v-deep .md-ripple {
    @apply z-10;
  }
}
.welcome-img {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  display: inline-block;
}
.activity-app-card {
  @apply w-full max-w-none;
}

.activity-title {
  @apply font-medium text-2xl mb-12;
  display: flex;
  align-items: center;
}

.details-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .button {
    flex-grow: 2;
    max-width: 100%;
    width: fit-content;
    white-space: nowrap;
    margin-top: 10px;
    @apply mx-1;
  }
  @screen sm {
    .button + .button {
      z-index: 0;
    }
  }
}

.navigation {
  display: flex;
  width: 100%;
  justify-content: space-around;
  button {
    max-width: 200px;
  }
}

::v-deep .activity-tabs > .tabs-buttons {
  margin-bottom: 2.5rem;
  gap: 3rem;
  @apply mx-3;
  width: unset;
  .tabs-panel {
    @apply max-w-5xl w-full;
  }
  @screen xs {
    .tab-button-inner {
      font-size: 3.5vw;
    }
  }
  @screen sm {
    .tab-button-inner {
      font-size: unset;
    }
  }
}
::v-deep .activity-tabs > .tabs-buttons .tab-button::after {
  bottom: -10px;
  background-color: var(--theme-text-primary, rgba(51, 51, 51, 1));
  right: 15px;
  left: 15px;
}
::v-deep .activity-tabs > .tabs-buttons .tab-button {
  flex: 0 1;
  width: fit-content;
  text-wrap: nowrap;
  padding: 0px 15px;
  height: 2rem;
  margin: 0.5rem -10px;
  border-radius: 10px;
  color: rgba(136, 144, 160, 1);
  @screen sm {
    flex: 0 1;
  }
  @screen xs {
    display: inline-block;
  }
}
::v-deep .activity-tabs > .tabs-buttons .tab-button--active {
  color: var(--theme-text-primary, rgba(51, 51, 51, 1));
}

::v-deep .activity-tabs > .tabs-buttons .tab-button:last-child::before {
  content: 'New!';
  padding: 1px 10px;
  border-radius: 10px;
  color: var(--theme-color-primary, rgba(0, 108, 224, 1));
  font-size: 13px;
  background-color: rgba(218, 232, 245, 1);
  position: absolute;
  top: 3px;
  right: 8px;
}

::v-deep .activity-tabs > .tabs-buttons .tab-button:last-child::after {
  right: 80px;
}

::v-deep .activity-tabs > .tabs-buttons .tab-button:last-child {
  padding-right: 5rem;
}

::v-deep .activity-tabs-wrapper-card {
  margin: 0px;
  max-width: none;
  background-color: transparent;
}
::v-deep .activity-tabs-wrapper-card > .card-content {
  padding: 0px;
}
.corporate-heading {
  @apply max-w-5xl mt-8 mx-3 text-lg font-semibold;
  p {
    @apply leading-normal truncate;
  }
  @screen sm {
    @apply text-2xl mx-0;
  }
}

::v-deep .hide-tabs > .tabs-buttons {
  display: none;
}
</style>
