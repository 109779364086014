<template>
  <div class="quick-transfer-summary-wrapper">
    <div class="relative">
      <div v-if="!form.amountTo || !form.selectedQuote" class="summary-no-data">
        <IconFundAction />
        <h1 class="no-data-title">{{ $t('ComponentFundBalanceSummary.NoDataTitle').value }}</h1>
        <p class="no-data-text">{{ $t('ComponentFundBalanceSummary.NoDataText').value }}</p>
      </div>
      <div v-else>
        <h1 class="summary-title">
          {{ $t('ComponentFundBalanceSummary.SectionTitle').value }}
        </h1>
        <CardWarning v-if="shouldShowRateAlert && isAcceptedTermsAndConditions">
          <template #text>
            {{ $t('ComponentQuickTransferSummary.RateChangeWarningText').value }}
          </template>
        </CardWarning>
        <div v-if="form && form.selectedQuote" class="my-8">
          <QuickTransferSummaryItem
            icon="clock"
            :title="$t('ComponentQuickTransferSummary.ItemShouldArriveText').value"
            :value="estimatedTimeOfArrival"
          />
          <QuickTransferSummaryItem
            icon="coins"
            :title="$t('ComponentQuickTransferSummary.ItemTransferFeesText').value"
            :value="`${addPrecision(form.selectedQuote.fee)} ${form.currencyFrom}`"
          />

          <div class="total-to-pay">
            <p class="total-to-pay-text">
              {{ $t('ComponentConvertBalanceSummary.TotalToPayText').value }}
            </p>
            <p
              v-if="!isQuoteLoading"
              class="total-to-pay-amount"
              data-test-id="fund-balance-summary-p-totalToPayAmount"
            >
              {{ `${formatCurrency(form.currencyFrom, totalToPay)} ${form.currencyFrom}` }}
            </p>
            <AppSkeleton
              class="qt-skeleton-loader"
              loading
              v-if="isQuoteLoading"
              height="14px"
              width="100px"
            />
          </div>
        </div>

        <AppInputCheckbox
          :value="isAcceptedTermsAndConditions"
          @input="onCheck"
          class="terms-and-conditions-checkbox"
          :class="{ loading: isProcessingTransaction || isQuoteDataInvalid || isQuoteLoading }"
          data-test-id="fund-balance-summary-checkbox-acceptTermsAndConditions"
        >
          <span
            v-html="
              $t('ComponentTermsAndConditionsModal.TextIAgree', {
                termsAndConditionsURL: termsAndConditionsURL,
              }).value
            "
          />
        </AppInputCheckbox>
        <AppButton
          :disabled="isConfirmButtonDisabled"
          :loading="isProcessingTransaction"
          @click="$emit('confirm')"
          data-test-id="fund-balance-summary-button-confirmAndFund"
        >
          {{ $t('ComponentFundBalanceSummary.ButtonConfirmText').value }}
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import AppSkeleton from '@oen.web.vue2/ui/src/components/AppSkeleton/AppSkeleton.vue'

import {
  useI18nStore,
  useResourcesStore,
  useSendMoneyStore,
  useQuickTransferStore,
} from '@galileo/stores'

import {
  AppCard,
  AppIcon,
  AppDescriptionListItem,
  AppButton,
  AppCardHeader,
  AppInputCheckbox,
  AppSpinnerBig,
} from '@oen.web.vue2/ui'

import { formatCurrency } from '@galileo/composables/useCurrency'
import { IconSmallClock, IconCoins, IconReceipt } from '@galileo/assets/icons/vue'
import CardWarning from '@galileo/components/CardWarning/CardWarning'
import QuickTransferSummaryItem from '@galileo/components/Views/QuickTransfer/QuickTransferSummary/QuickTransferSummaryItem.vue'
import { addPrecision } from '@galileo/utilities/filters'
import { IconFundAction } from '@galileo/assets/icons/quickTransferActions'

export default {
  name: 'FundBalanceSummary',
  emits: ['confirm', 'on-accept-terms-click'],
  components: {
    AppSkeleton,
    AppCard,
    AppButton,
    AppIcon,
    IconSmallClock,
    AppDescriptionListItem,
    IconCoins,
    IconReceipt,
    AppCardHeader,
    AppInputCheckbox,
    CardWarning,
    AppSpinnerBig,
    QuickTransferSummaryItem,
    addPrecision,
    IconFundAction,
  },
  props: {
    isInvalid: {
      type: Boolean,
      required: true,
    },
    isQuoteLoading: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const resourcesStore = useResourcesStore()
    const sendMoneyStore = useSendMoneyStore()
    const quickTransferStore = useQuickTransferStore()

    const isProcessingTransaction = computed(() => quickTransferStore.isProcessingTransaction)

    const recipient = computed(() => sendMoneyStore.form.recipient)

    const shouldShowRateAlert = computed(() => sendMoneyStore.didRateChange)

    const form = computed(() => sendMoneyStore.form)

    const isAcceptedTermsAndConditions = ref(false)

    const isQuoteDataInvalid = computed(
      () => !form.value.selectedQuote && !form.value.quoteErrorFrom && !form.value.quoteErrorTo
    )

    const isConfirmButtonDisabled = computed(
      () =>
        props.isInvalid ||
        !isAcceptedTermsAndConditions.value ||
        props.isQuoteLoading ||
        isProcessingTransaction.value
    )

    const totalToPay = computed(
      () => form.value.selectedQuote.fee + form.value.selectedQuote.amountFrom
    )

    const estimatedTimeOfArrival = computed(() => {
      if (form.value.selectedQuote?.time) {
        return $t(form.value.selectedQuote.time).value
      }
      return ' '
    })

    const onCheck = () => {
      if (isProcessingTransaction.value) {
        return
      }

      if (!props.isInvalid) {
        isAcceptedTermsAndConditions.value = !isAcceptedTermsAndConditions.value
      }
      
      emit('on-accept-terms-click', isAcceptedTermsAndConditions.value)
    }

    watch(
      () => props.isInvalid,
      (isInvalid) => {
        if (isInvalid) {
          isAcceptedTermsAndConditions.value = false
        }
      }
    )

    watch(
      () => sendMoneyStore.getConfirmedRate(),
      (confirmedRate) => {
        if (confirmedRate === null && isAcceptedTermsAndConditions) {
          sendMoneyStore.setConfirmedRate()
        }
      }
    )

    return {
      isQuoteDataInvalid,
      isProcessingTransaction,
      $t,
      isAcceptedTermsAndConditions,
      termsAndConditionsURL: resourcesStore.getTermsAndConditionsUrl,
      form,
      recipient,
      shouldShowRateAlert,
      totalToPay,
      estimatedTimeOfArrival,
      onCheck,
      addPrecision,
      formatCurrency,
      isConfirmButtonDisabled,
    }
  },
}
</script>

<style scoped>
.summary-title {
  @apply mb-4 text-xl text-grayblue-400 font-semibold;
}

.quick-transfer-summary-wrapper {
  @apply flex flex-col bg-white rounded-xl mx-auto p-8;
  flex: 1 1 0px;
  min-width: 300px;
}

.total-to-pay {
  @apply bg-gray-200 rounded-lg p-2 flex justify-between;
}
.total-to-pay-text {
  @apply text-grayblue-300 font-semibold;
}
.total-to-pay-amount {
  @apply text-grayblue-400 font-semibold;
}

::v-deep .input-checkbox.input-checkbox--with-label {
  @apply items-center;
}
.input-checkbox-label {
  @apply text-sm;
}
.input-checkbox--disabled {
  @apply opacity-50;
}

::v-deep .card-warning {
  @apply bg-yellow-lighter mb-0;
  font-size: 0.85rem;
}

::v-deep .input-checkbox .input-checkbox-label {
  @apply text-sm;
}

::v-deep .input-checkbox.loading {
  @apply text-gray-disabled pointer-events-none;
  .input-checkbox-virtual-checkbox-check {
    @apply border-gray-disabled;
  }
  .link {
    @apply text-gray-disabled;
  }
  &.input-checkbox--checked
    .input-checkbox-virtual-checkbox
    .input-checkbox-virtual-checkbox-check {
    @apply bg-gray-disabled;
  }
}

::v-deep .input-checkbox.input-checkbox--checked.input-checkbox--disabled .input-checkbox-label {
  @apply bg-transparent;
}

::v-deep .input-checkbox--checked .input-checkbox-virtual-checkbox:after {
  @apply hidden;
}

::v-deep
  .input-checkbox.input-checkbox--checked
  .input-checkbox-virtual-checkbox
  .input-checkbox-virtual-checkbox-check {
  @apply bg-dark-bg;
}
::v-deep .qt-skeleton-loader .skeleton {
  @apply bg-gray-500 rounded-full;
}

.terms-and-conditions-checkbox {
  @apply mb-3 mt-auto;
}
.summary-no-data {
  @apply flex py-16 items-center flex-col gap-3 flex-grow;
}
.no-data-title {
  @apply font-semibold;
}
.no-data-text {
  @apply text-sm text-gray-text text-center;
}
</style>
