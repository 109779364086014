<template>
  <div class="action-block">
    <div class="actions-form-wrapper">
      <QTStylesWrapper class="flex flex-col gap-4 qt-app-input-ammount-currency">
        <AppInputAmountCurrency
          v-model="form.amountTo"
          :data-value="form.amountTo"
          should-reset-filter-on-focus
          :disabled="isProcessingTransaction || fetchBalanceListLoading"
          analytics-name="send-money-amount-to"
          :class="{
            'hide-disabled-styles': !isProcessingTransaction,
          }"
          :loading="fetchBalanceListLoading"
          :validation="validation.amountTo"
          :currencies="tradableBalanceList"
          :locale="deviceStore.getUserLocale"
          :currency.sync="form.currencyTo"
          filter
          filter-check-additional-field
          :label="$t('PageFundBalance.BalanceToFundLabel').value"
          additional-field-name="description"
          :filter-placeholder="$t('PageSendMoneyAmount.TypeToSearchPlaceholderText').value"
          :currency-label="$t('PageSendMoneyAmount.SelectCurrencyLabelText').value"
          expand-currency-input
          force-precision
        >
          <template #currency_selected="{ option }">
            <div class="flex flex-col justify-center h-full relative">
              <div class="flex flex-row mb-1">
                <CircularFlag
                  class="mr-3"
                  :currency-code="option.value"
                />
                <p data-test-id="fund-balance-p-selectedToFundCurrencyCode">
                  {{ option.value }}
                </p>
              </div>
              <p class="text-xs text-grayblue-300 -mb-2 leading-4 absolute bottom-2 right-0">
                {{ formatCurrency(option.value, option.availableBalance) }}
                {{ $t('FundBalance.Available').value }}
              </p>
            </div>
          </template>
          <template #currency_option="{ option }">
            <div class="flex items-center gap-4">
              <CircularFlag
                include-border
                size="40"
                :currency-code="option.value"
              />
              <div class="">
                <span class="font-semibold text-base">{{ option.value }}</span>
                <p class="text-sm leading-4">
                  {{ formatCurrency(option.value, option.availableBalance) }}
                  {{ $t('FundBalance.Available').value }}
                </p>
              </div>
            </div>
          </template>
        </AppInputAmountCurrency>
        <SendMoneyQuoteErrorMessage
          v-if="quoteErrorText && form.amountTo"
          class="error-message"
          :error-text="quoteErrorText"
        />
        <QuickTransferSendRate
          :loading="isQuoteLoading"
          data-test-id="fund-balance-span-sendRate"
        />
      </QTStylesWrapper>
      <QTStylesWrapper class="qt-xe-input-currency">
        <XeInputCurrency
          v-model="form.currencyFrom"
          :currencies="currencyList"
          should-reset-filter-on-focus
          :label="$t('PageFundBalance.CurrencyToPayInLabel').value"
          :disabled="disableInput"
          use-circular-flags
          selected-display-field="description"
          data-test-id="fund-balance-input-currencyToPayIn"
        />
      </QTStylesWrapper>

      <QTStylesWrapper class="qt-app-input-dropdown">
        <PaymentMethodDropdown
          v-model="form.paymentMethod"
          :options="availablePaymentMethods"
          :disabled="disablePaymentMethodSelection"
          :validation="validation.paymentMethod"
        />
      </QTStylesWrapper>
      <AppButton
        v-if="form.paymentMethod === PAYMENT_METHODS.BANK_TRANSFER"
        class="instructions-button"
        theme="secondary"
        data-test-id="fund-balance-bankTransferInstructionButton"
        @click="openWireTransferInstructions"
      >
        <AppIcon class="h-fit">
          <IconInformation />
        </AppIcon>
        <p>
          {{ $t('QuickTransfer.WireInstructionsButtonText', { paymentMethodTitle }).value }}
        </p>
      </AppButton>

      <QTStylesWrapper
        v-if="
          showXeBankProviders &&
            form.paymentMethod === PAYMENT_METHODS.BANK_TRANSFER &&
            selectedQuote
        "
        class="qt-app-input-dropdown"
      >
        <XeBankAccountSelectionDropdown
          :bank-providers="xeBankProviders"
          :disabled="isProcessingTransaction || isQuoteLoading"
          :validation="validation.paymentMethodId"
          @select="submitBankProvider($event)"
        />
      </QTStylesWrapper>
      <QTStylesWrapper
        v-if="
          selectedQuote &&
            selectedQuote.deliveryMethod === DELIVERY_METHODS.FUNDS_ON_BALANCE &&
            form.paymentMethod === PAYMENT_METHODS.DIRECT_DEBIT
        "
        class="qt-app-input-dropdown"
      >
        <BankSelectionDropdown
          :disabled="disableBankSelectionDropdown"
          :validation="validation.paymentMethodId"
        />
      </QTStylesWrapper>
    </div>

    <FundBalanceSummary
      :is-invalid="validation.$anyInvalid"
      :is-quote-loading="isSummaryLoading"
      @confirm="submit"
      @on-accept-terms-click="onAcceptTermsClick"
    />
    <PendingApprovalModal v-model="showPendingApprovalModal" />
    <WireTransferInstructionsModal
      v-if="showWireTransferWarning"
      :button-text="$t('ComponentXeTransferDetails.ButtonUnderstand').value"
      @close="closeWireTransferInfoModal"
    />
    <ModalRouterView />
    <BasicEddFieldsModal
      :open="showBasicEddModal"
      :disabled="!sendMoneyStore.isSubIndustrySelected"
      @on-subindustry-select="onSubIndustrySelect"
      @on-close="onBasicEddModalClose"
    />
  </div>
</template>

<script>
import {
  AppInputAmountCurrency,
  AppDescriptionList,
  AppInputToggle,
  AppInputText,
  AppCard,
  AppIcon,
  AppInputRadioButtons,
  AppButton,
  AppInputDropdown,
} from '@oen.web.vue2/ui'
import { computed, reactive, ref, toRef, watch, onBeforeMount } from '@vue/composition-api'
import { usePromiseLazy, useValidation } from 'vue-composable'
import PendingApprovalModal from '@galileo/components/Views/SendMoney/Amount/PendingApprovalModal.vue'
import { useRouter } from '@galileo/composables/useRouter'
import { required } from '@vuelidate/validators'
import { SendMoneyRate } from '@galileo/components/Views/SendMoney/Organisms'
import QTStylesWrapper from '@galileo/components/Views/QuickTransfer/StyleWrapperComponents/QTStylesWrapper'
import SendMoneyQuoteErrorMessage from '@galileo/components/Views/SendMoney/SendMoneyQuoteErrorMessage'
import {
  QUOTE_SCREEN,
  PAYMENT_METHODS,
  DELIVERY_METHODS,
  TRANSFER_TYPE,
} from '@galileo/constants/sendMoneyFlow.const.js'
import { IconInformation, IconCheckmark } from '@oen.web.vue2/icons'
import XeTooltip from '@galileo/components/XeTooltip/XeTooltip'
import QuickTransferSendRate from '@galileo/components/Views/QuickTransfer/QuickTransferSendRate/QuickTransferSendRate'
import RecipientSelectionDropdown from '@galileo/components/Views/QuickTransfer/RecipientDropdown/RecipientSelectionDropdown'
import BankSelectionDropdown from '@galileo/components/Views/QuickTransfer/BankSelectionDropdown/BankSelectionDropdown'
import QuickTransferActionsPageWrapper from '@galileo/components/Views/QuickTransfer/QuickTransferActionPageWrapper/QuickTransferActionsPageWrapper.vue'
import {
  useAnalyticsStore,
  useAuthStore,
  useDeviceStore,
  usePaymentsStore,
  useSendMoneyStore,
  useI18nStore,
  useRecipientsStore,
  useQuickTransferStore,
  useAppStore,
  useBalancesStore,
  useKYCRefreshCorporateStore
} from '@galileo/stores'
import { SEGMENT_EVENTS, SEGMENT_PAYMENT_METHOD_TYPES } from '@galileo/constants/segmentAnalytics'
import XeBankAccountSelectionDropdown from '@galileo/components/Views/QuickTransfer/XeBankAccount/XeBankAccountSelectionDropdown.vue'
import FundBalanceSummary from '@galileo/components/Views/Balances/FundBalance/FundBalanceSummary/FundBalanceSummary.vue'
import { ACCOUNT_KYC_STATUS } from '@galileo/constants/accountKycStatus.const.ts'
import { getFlag } from '@galileo/composables/useFlag'
import WireTransferInstructionsModal from '@galileo/components/Views/SendMoney/Modals/WireTransferInstructionsModal'
import XeInputCurrency from '@galileo/components/XeInputCurrency/XeInputCurrency.vue'
import CircularFlag from '@galileo/components/CircularFlag/CircularFlag.vue'
import { formatCurrency } from '@galileo/composables/useCurrency'
import ModalRouterView from '@galileo/views/ModalRouterView'
import { useFetchUpdateQuotes } from '@galileo/composables/useFetchUpdateQuote'
import PaymentMethodDropdown from '@galileo/components/Views/QuickTransfer/PaymentMethodDropdown/PaymentMethodDropdown'
import { getAllInfoByCountry } from '@galileo/composables/useIsoCountryCurrencyLibrary'
import { useBasicEddDialog } from '@galileo/components/Views/QuickTransfer/Modals/BasicEddFields/hooks/use-basic-edd-modal'
import BasicEddFieldsModal from '@galileo/components/Views/QuickTransfer/Modals/BasicEddFields/ui/BasicEddFieldsModal.vue'
import { isEmpty } from 'lodash-es'

export default {
  name: 'FundBalance',
  components: {
    QuickTransferActionsPageWrapper,
    CircularFlag,
    AppInputDropdown,
    XeInputCurrency,
    QuickTransferSendRate,
    AppInputAmountCurrency,
    AppDescriptionList,
    AppInputToggle,
    AppInputText,
    SendMoneyRate,
    AppCard,
    IconInformation,
    AppIcon,
    XeTooltip,
    AppInputRadioButtons,
    RecipientSelectionDropdown,
    BankSelectionDropdown,
    IconCheckmark,
    AppButton,
    XeBankAccountSelectionDropdown,
    FundBalanceSummary,
    QTStylesWrapper,
    PendingApprovalModal,
    SendMoneyQuoteErrorMessage,
    WireTransferInstructionsModal,
    ModalRouterView,
    PaymentMethodDropdown,
    BasicEddFieldsModal,
  },
  props: {},
  setup() {
    const sendMoneyStore = useSendMoneyStore()
    const deviceStore = useDeviceStore()
    const authStore = useAuthStore()
    const paymentsStore = usePaymentsStore()
    const { $t } = useI18nStore()
    const router = useRouter()
    const analyticsStore = useAnalyticsStore()
    const recipientsStore = useRecipientsStore()
    const quickTransferStore = useQuickTransferStore()
    const appStore = useAppStore()
    const balancesStore = useBalancesStore()
    const kycRefreshCorporateStore = useKYCRefreshCorporateStore()

    const showPendingApprovalModal = ref(false)
    const sameCurrencyFromTradingText = ref('')
    const bankProvidersLoading = ref(false)

    const availablePaymentMethods = computed(() => {
      if (sendMoneyStore.form.availableQuotes === null) {
        return []
      }

      const fundBalanceQuotes = sendMoneyStore.form.availableQuotes.filter((quote) => {
        const isDeliveryMethodFundsOnBalance =
          quote?.deliveryMethod === DELIVERY_METHODS.FUNDS_ON_BALANCE

        const isPaymentMethodFundsOnBalance =
          quote.paymentMethod === PAYMENT_METHODS.FUNDS_ON_BALANCE

        return isDeliveryMethodFundsOnBalance && !isPaymentMethodFundsOnBalance
      })

      return fundBalanceQuotes.map((quote) => ({
        ...quote,
        value: quote.paymentMethod,
        disabled: !quote.isEnabled,
        currency: form.currencyFrom,
      }))
    })

    const { fetchNewQuote, updatePreviousQuote, isQuoteLoading } = useFetchUpdateQuotes()

    const isSummaryLoading = computed(() => {
      return bankProvidersLoading.value || isQuoteLoading.value
    })

    const showWireTransferWarning = ref(false)

    const disableInput = computed(
      () => isQuoteLoading.value || isProcessingTransaction.value || fetchBalanceListLoading.value
    )

    const disableBankSelectionDropdown = computed(
      () =>
        isProcessingTransaction.value ||
        availablePaymentMethods.value.length === 0 ||
        isQuoteLoading.value
    )

    const disablePaymentMethodSelection = computed(
      () =>
        isProcessingTransaction.value ||
        availablePaymentMethods.value.length === 0 ||
        isQuoteLoading.value
    )

    const fetchBalanceListLoading = ref(false)
    const routeFrom = appStore.getCurrentRoute.from

    onBeforeMount(async () => {
      let isFormReset = false
      if (!appStore.isFromIncompleteTransaction) {
        sendMoneyStore.restoreDefaultForm()
        isFormReset = true
      }
      quickTransferStore.isProcessingTransaction = false
      const locationFrom = appStore.currentRoute.params.locationFrom
      sendMoneyStore.form.transferType = TRANSFER_TYPE.FUND_BALANCE
      validation.$reset()
      fetchBalanceListLoading.value = true

      const nativeCurrency = getAllInfoByCountry(authStore.userProfile.country).currency

      await balancesStore.fetchTradableBalanceList()
      fetchBalanceListLoading.value = false
      sendMoneyStore.form.countryTo = authStore.user.country
      sendMoneyStore.form.deliveryMethod = DELIVERY_METHODS.FUNDS_ON_BALANCE

      form.currencyFrom = sendMoneyStore.getCurrencyFrom
      validation.$reset()

      if (isFormReset) {
        if (routeFrom.name === 'IndividualCurrency') {
          form.currencyTo = routeFrom.params.currency
        } else {
          form.currencyTo = nativeCurrency
        }
        form.amountTo = sendMoneyStore.getDefaultAmountFrom()
      }

      let fundCurrency = sendMoneyStore.getCurrencyTo
      fundCurrency = appStore.currentRoute.params.currency || fundCurrency

      await getXeBankProviders()

      analyticsStore.track({
        event: SEGMENT_EVENTS.FUND_BALANCE_ACCESSED,
        traits: {
          fundCurrency: fundCurrency,
          location: locationFrom,
          tbuId: authStore.user.customer.businessUnitId,
          region: authStore.user.customer.region,
        },
      })
    })

    const currencyList = computed(() => {
      return sendMoneyStore.getCombinedAvailableCurrenciesFrom.filter(
        (ccy) => !(ccy.sameCurrencySupported === false && ccy.value === form.currencyTo)
      )
    })

    const PROPERTY_CHANGED = {
      get DELIVERY_METHOD() {
        PROPERTY_CHANGED._deliveryMethodChanges++
        return {
          counter: PROPERTY_CHANGED._deliveryMethodChanges,
          name: 'delivery method',
        }
      },

      get PAYMENT_METHOD() {
        PROPERTY_CHANGED._paymentMethodChanges++
        return {
          counter: PROPERTY_CHANGED._paymentMethodChanges,
          name: 'payment method',
        }
      },

      _deliveryMethodChanges: 0,
      _paymentMethodChanges: 0,
    }

    const xeBankProviders = ref([])

    const shouldShowRate = computed(() => !validation.$anyInvalid || validation.$anyDirty)

    const form = reactive({
      paymentMethod: computed({
        get: () => sendMoneyStore.form.paymentMethod,
        set: async (method) => {
          // no calculate needed since we already have the quotes
          sendMoneyStore.setPaymentMethodAction(method)
          const selectedPaymentMethod = availablePaymentMethods.value.find(
            (paymentMethod) => paymentMethod.value === method
          )

          sendMoneyStore.setPaymentMethodType(selectedPaymentMethod)
          updateBankTransferId()
          validation.paymentMethodId.$reset()
        },
      }),
      amountTo: computed({
        get: () => sendMoneyStore.getAmountTo(false),
        set: async (amount) => {
          sendMoneyStore.form.amountTo = amount
          if (amount !== 0) {
            await fetchNewQuote({ fixedInput: QUOTE_SCREEN.AMOUNT_TO })
          }
        },
      }),
      currencyTo: computed({
        get: () => sendMoneyStore.form.currencyTo,
        set: async (currency) => {
          if (currency !== sendMoneyStore.form.currencyTo) {
            sendMoneyStore.form.currencyTo = currency
            sendMoneyStore.form.shouldCalcAmountFrom = true
            let currencyFrom = sendMoneyStore.getCombinedAvailableCurrenciesFrom.find(
              (ccy) => ccy.value === currency
            )

            if (!currencyFrom.sameCurrencySupported && currency === form.currencyFrom) {
              sendMoneyStore.form.currencyFrom = sendMoneyStore.getFirstFavoriteCurrency(
                form.currencyTo
              ).value
            }
            if (form.amountTo && form.amountTo > 0) {
              await fetchNewQuote({ fixedInput: QUOTE_SCREEN.AMOUNT_TO, debounceTime: 0 })
            }
          }
        },
      }),
      currencyFrom: computed({
        get: () => sendMoneyStore.getCurrencyFrom,
        set: async (currency) => {
          if (currency !== sendMoneyStore.form.currencyFrom) {
            sendMoneyStore.form.currencyFrom = currency

            let currencyFrom = currencyList.value.find((ccy) => ccy.value === currency)

            if (!currencyFrom.sameCurrencySupported && currency === form.currencyTo) {
              sendMoneyStore.form.currencyFrom = sendMoneyStore.getFirstFavoriteCurrency(
                form.currencyTo
              ).value
            }

            await getXeBankProviders()

            if (form.amountTo !== 0) {
              await fetchNewQuote({ fixedInput: QUOTE_SCREEN.AMOUNT_TO, debounceTime: 0 })
              await updateBankTransferId()
            }
          }
        },
      }),
    })

    watch(
      () => form.paymentMethod,
      async (value) => {
        await updateBankTransferId()
      }
    )

    const getXeBankProviders = async () => {
      bankProvidersLoading.value = true
      xeBankProviders.value = await paymentsStore.getBankProviders(sendMoneyStore.form.currencyFrom)
      bankProvidersLoading.value = false
    }

    const disablePaymentRadioButtons = computed(
      () =>
        isProcessingTransaction.value ||
        availablePaymentMethods.value.length === 0 ||
        isQuoteLoading.value
    )

    const showXeBankProviders = ref(false)

    const paymentMethodTitle =
      authStore.userProfile.country === 'US'
        ? $t('PageSendMoneyPayment.WireTransfer').value
        : $t('PageSendMoneyPayment.BankTransfer').value

    const submitBankProvider = async (providerId) => {
      const selectedXeBankProvider = xeBankProviders.value.find((bank) => bank.id === providerId)
      sendMoneyStore.form.paymentMethodId = selectedXeBankProvider.id

      sendMoneyStore.setPaymentMethodName({
        paymentMethodTitle: paymentMethodTitle,
        paymentMethodCaption: selectedXeBankProvider.bankName,
        paymentMethodText: selectedXeBankProvider.bankName,
      })

      analyticsStore.track({
        event: SEGMENT_EVENTS.PAYMENT_METHOD_SELECTED,
        traits: {
          paymentType: SEGMENT_PAYMENT_METHOD_TYPES.STAGED,
        },
      })
    }
    const isProcessingTransaction = computed(() => quickTransferStore.isProcessingTransaction)

    const updateBankTransferId = async () => {
      if (form.paymentMethod === PAYMENT_METHODS.BANK_TRANSFER) {

        sendMoneyStore.form.paymentMethodId = null
        validation.paymentMethodId.$reset()

        if (xeBankProviders.value?.length === 1) {
          showXeBankProviders.value = false
          const onlyAvailableBank = xeBankProviders.value[0].id
          submitBankProvider(onlyAvailableBank) // case when there is only one xe bank provider!!!
        } else {
          showXeBankProviders.value = true
        }
      }
      validation.paymentMethodId.$reset()
    }

    const validation = useValidation({
      quoteId: {
        $value: computed(() => sendMoneyStore.form.quoteId),
      },
      paymentMethod: {
        $value: toRef(form, 'paymentMethod'),
        required,
        isInvalid: {
          $validator(v) {
            const selectedPaymentMethod = availablePaymentMethods.value.find(
              (method) => method.value === v
            )
            return !selectedPaymentMethod?.errorText
          },
          $message: ' ',
        },
      },
      amountFrom: {
        $value: computed(() => sendMoneyStore.form.amountFrom),
        required,
        isInvalid: {
          $validator() {
            return !sendMoneyStore.form.quoteErrorFrom
          },
          message: ' ',
        },
      },
      amountTo: {
        $value: toRef(form, 'amountTo'),
        isInvalid: {
          $validator() {
            return !sendMoneyStore.form.quoteErrorTo
          },
          $message: ' ',
        },
        required: {
          $validator(v) {
            return v > 0
          },
          $message: $t('PageSendMoneyAmount.AmountToRequired').value,
        },
      },
      paymentMethodId: {
        // We want to make sure that paymentmethodId has been set before we subimt transfer
        $value: computed(() => sendMoneyStore.form.paymentMethodId),
        required: {
          $validator(value) {
            if (isQuoteLoading.value || form.paymentMethod === PAYMENT_METHODS.OPEN_BANKING) {
              return true
            }
            return !!value
          },
          $message: $t('QuickTransfer.BankAccountRequired').value,
        },
      },
    })

    const selectedCurrencyToDropdownOption = computed(() =>
      sendMoneyStore.getCombinedAvailableCurrenciesTo.find((c) => c.value === form.currencyTo)
    )

    const accountIsRestricted = sendMoneyStore.accountIsRestricted || authStore.userProfile.customer.accountRestricted

    const isAccountPendingVerification = computed(() => {
      return sendMoneyStore.isVerificationPending
    })

    const processOrder = reactive(
      usePromiseLazy(() =>
        sendMoneyStore.processOrder({
          transferId: sendMoneyStore.form.transferId,
          replacePage: false,
        })
      )
    )
    const isAccountNotApproved =
      authStore.userProfile.customer.accountKycStatus === ACCOUNT_KYC_STATUS.NOT_APPROVED

    const openWireTransferInstructions = () => {
      showWireTransferWarning.value = true
    }

    const closeWireTransferInfoModal = () => {
      showWireTransferWarning.value = false
    }

    const shouldUpdateQuote = computed(
      () =>
        validation.quoteId.$dirty ||
        validation.paymentMethod.$dirty ||
        validation.paymentMethodId.$dirty
    )

    const {
      basicEddErrors,
      showBasicEddModal,
      onBasicEddModalClose,
    } = useBasicEddDialog()

    const onAcceptTermsClick = async (isAccepted) => {
      if (!isAccepted && validation.$anyInvalid) {
        validation.$touch()
      } else if (isAccepted && shouldUpdateQuote.value) {
        sendMoneyStore.setConfirmedRate()

        // avoid opening basic EDD modal if form is already filled
        if (isEmpty(sendMoneyStore.form.filledAdditionalFields)) {
          basicEddErrors.value = sendMoneyStore.transferBasicResponse?.errors ?? []
        }

        await updatePreviousQuote()
        validation.$reset()
      }
    }

    const onSubIndustrySelect = (subIndustryId) => {
      sendMoneyStore.form.filledAdditionalFields = {
        ...sendMoneyStore.form.filledAdditionalFields,
        corpSubIndustry: subIndustryId,
      }
    }

    const submit = async () => {
      if (isAccountNotApproved) {
        showPendingApprovalModal.value = true
        return
      }
      
      if(accountIsRestricted) {
        router.push({ path: 'account-restricted', append: true })
        return
      }
      if (authStore.isCorporateAccount && kycRefreshCorporateStore.isOverdue) {
        kycRefreshCorporateStore.showModal({ isBlockingTransaction: true })
        return
      }

      quickTransferStore.isProcessingTransaction = true

      if (shouldUpdateQuote.value) {
        await updatePreviousQuote()
        validation.$reset()
      } else {
        sendMoneyStore.setConfirmedRate()
      }

      if (sendMoneyStore.didRateChange) {
        quickTransferStore.showRateChangedModal()
        sendMoneyStore.setConfirmedRate()
        return
      }

      //we stop refreshing the quote from now on.
      sendMoneyStore.clearRefreshQuote()
      sendMoneyStore.setConfirmedRate()

      await processOrder.exec()

      if (processOrder.error) {
        // TODO: Implement this logic for consumers
        if (!accountIsRestricted && !isAccountPendingVerification.value) {
          router.replace(processOrder.error)
        }
      } else {
        analyticsStore.track({
          event: SEGMENT_EVENTS.FUND_BALANCE_COMPLETED,
          traits: {
            fundCurrency: [form.currencyFrom, form.currencyTo],
            amount: form.amountTo,
            tbuId: authStore.user.customer.businessUnitId,
            region: authStore.user.customer.region,
          },
        })
        router.replace(processOrder.result)
      }
    }

    const quoteErrorText = computed(
      () => sendMoneyStore.form.quoteErrorTo || sendMoneyStore.form.quoteErrorFrom
    )

    return {
      updatePreviousQuote,
      submit,
      tradableBalanceList: computed(() => balancesStore.tradableBalanceList),
      isQuoteLoading,
      isSummaryLoading,
      fetchBalanceListLoading,
      currencyList,
      quickTransferStore,
      isProcessingTransaction,
      selectedCurrencyToDropdownOption,
      recipientsStore,
      form,
      showPendingApprovalModal,
      $t,
      validation,
      sendMoneyStore,
      deviceStore,
      getFlag,
      sameCurrencyFromTradingText,
      shouldShowRate,
      availablePaymentMethods,
      xeBankProviders,
      showXeBankProviders,
      submitBankProvider,
      PAYMENT_METHODS,
      DELIVERY_METHODS,
      showWireTransferWarning,
      openWireTransferInstructions,
      closeWireTransferInfoModal,
      paymentMethodTitle,
      updateBankTransferId,
      formatCurrency,
      disablePaymentRadioButtons,
      disableInput,
      disableBankSelectionDropdown,
      disablePaymentMethodSelection,
      quoteErrorText,
      selectedQuote: computed(() => sendMoneyStore.form.selectedQuote),
      onAcceptTermsClick,
      basicEddErrors,
      showBasicEddModal,
      onBasicEddModalClose,
      onSubIndustrySelect,
    }
  },
}
</script>
<style scoped>
.same-currency-flag {
  @apply p-2 rounded-md;
  @apply text-xs font-semibold;
  background: rgba(218, 232, 245, 1);
  color: rgba(0, 86, 179, 1);
}
/* end */
.reference-input-text {
  @apply m-0;
  ::v-deep {
    .input-wrapper {
      @apply mt-0;
    }

    .input-input {
      @apply h-14 border-2 border-solid pt-8 pb-2 type-h2 border-transparent outline-none text-base font-normal;
      background-color: #e8ebee;
      box-shadow: unset;
      &:focus {
        @apply border-gray-light;
      }
    }

    .input-label {
      @apply relative;
      top: 1.75rem;
      left: 0.75rem;
      @apply font-normal text-xs;
      z-index: 1;
    }
  }
}
::v-deep .reference-input-text.input--error .input-input:focus {
  @apply border-red;
  box-shadow: unset;
}
::v-deep .message {
  @apply mb-0;
}
.instructions-button {
  @apply border border-gray-light rounded-lg bg-transparent !important;
  ::v-deep .button-inner.ripple.md-ripple {
    @apply flex justify-start gap-3 text-sm;
  }
}
.instructions-button:hover {
  @apply bg-white !important;
}

::v-deep .tooltip-wrapper.currency-to-tooltip .tooltip-tip {
  @apply bg-grayblue-400;
  &::before {
    border-left-color: theme('colors.grayblue.400');
  }
}
::v-deep .currency-input .input-wrapper .input-input {
  @apply py-2;
  .selected-value {
    @apply h-full;
  }
}
.disabled-radio-btns {
  @apply pointer-events-none opacity-50;
}
.qt-xe-input-currency {
  ::v-deep .p-dropdown-panel.p-component {
    @apply mt-3;
  }
}
::v-deep .error-message {
  @apply -mt-4;
}
</style>
