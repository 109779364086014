<!-- 
 
Usage guide:
This component should be the sole child of a components template element

-->

<template>
  <div class="actions-background-screen">
    <div class="qt-page">
      <div class="top-banner">
        <AppLink
          href="/activity"
          analytics-name="sidebar-logo-link"
          aria-label="Xe Logo"
          :class="{ branded: isBranded }"
        >
          <XeLogo />
        </AppLink>
        <ProfileSelectionDropdown />
      </div>
      <div class="qt-page-content">
        <XeBackButton icon-style="x" @click="backButtonHandler" class="close-button" />
        <QuickTransferActionsDropdown />
        <hr />
        <router-view />
      </div>
    </div>
    <RateChangeWarningModal v-if="shouldShowRateChangedModal" />
  </div>
</template>

<script>
import { AppIcon, AppLink } from '@oen.web.vue2/ui'
import { IconClose } from '@oen.web.vue2/icons'
import { useRouter } from '@galileo/composables/useRouter'
import ProfileSelectionDropdown from '@galileo/components/Views/Account/Profile/ProfileSelectionDropdown'
import XeLogo from '@galileo/components/XeLogo/XeLogo'
import {
  useThemeStore,
  useAppStore,
  useSendMoneyStore,
  useQuickTransferStore,
} from '@galileo/stores'
import { computed, onBeforeMount } from '@vue/composition-api'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import QuickTransferActionsDropdown from '@galileo/components/Views/QuickTransfer/QuickTransferActionsDropdown/QuickTransferActionsDropdown.vue'
import RateChangeWarningModal from '@galileo/components/Views/QuickTransfer/Modals/RateChangeWarningModal'

export default {
  name: 'QuickTransferActionPageWrapper',
  emits: [],
  components: {
    AppIcon,
    IconClose,
    ProfileSelectionDropdown,
    AppLink,
    XeLogo,
    XeBackButton,
    QuickTransferActionsDropdown,
    RateChangeWarningModal,
  },
  props: {},

  setup(props, { emit }) {
    const router = useRouter()
    const appStore = useAppStore()
    const sendMoneyStore = useSendMoneyStore()
    const quickTransferStore = useQuickTransferStore()

    const isBranded = computed(() => useThemeStore().isBranded)

    onBeforeMount(() => {
      if (!appStore.isFromIncompleteTransaction) {
        appStore.accessedFormsFromPath = appStore.currentRoute.from.path
      }
    })

    const backButtonHandler = () => {
      if (
        appStore.currentRoute.from.name === 'SendMoneyPayment' ||
        appStore.currentRoute.from.name === 'SendMoneySummaryEdit'
      ) {
        router.push({ name: 'SendMoneyAmount' })
      } else {
        router.replace({ path: appStore.accessedFormsFromPath })
      }
      sendMoneyStore.restoreDefaultForm()
    }

    return {
      router,
      isBranded,
      backButtonHandler,
      shouldShowRateChangedModal: computed(() => quickTransferStore.shouldShowRateChangedModal),
    }
  },
}
</script>

<style>
.actions-background-screen {
  @apply w-full min-h-full;
  background-color: var(--theme-background, rgba(243, 244, 246, 1));
}
.actions-form-wrapper {
  @apply flex flex-col gap-4 flex-grow w-96;
}
.action-block {
  @apply my-6 flex flex-row flex-wrap gap-7 w-full max-w-4xl;
}
</style>

<style scoped>
.qt-page {
  @apply flex-wrap flex flex-col items-center mx-4;
}
.top-banner {
  @apply h-20 flex flex-row justify-between  w-full items-center  mb-2;
  @screen sm {
    @apply pl-4 pt-8;
    padding-bottom: 11px;
  }
  ::v-deep .profile-selector-button {
    @apply gap-3;
    @screen sm {
      @apply gap-0;
    }
  }
}
.close-button {
  @apply mb-6;
  ::v-deep .button-inner {
    @apply justify-start;
  }
}
.qt-page-content {
  @apply w-full max-w-4xl mb-16;
  hr {
    @apply border-gray-200 border-b my-6;
  }
}
.i-want-to-dropdown {
  @apply w-full bg-gray-400 rounded-lg py-2 px-5;
}

::v-deep {
  .profile-selector {
    @apply static;
    @screen sm {
      margin-top: 19px;
      @apply p-0;
    }
  }
  .profile-selector-menu {
    height: fit-content;
    @apply top-20;
    @screen sm {
      @apply right-5;
    }
  }
}
</style>
